import React from 'react';

const Slide = ({ slide, slideIndex, currentIndex }) => {
  const isActive = currentIndex === slideIndex; // Determine if slide is active

  const handleTouchStart = (event) => {
    // Implement your custom swipe handling logic here
  };

  return (
    <div className={`slide ${isActive ? 'active' : ''}`} onTouchStart={handleTouchStart}>
      {slide.title && <center><h3 className="slide-title">{slide.title}</h3></center>}
      {slide.image && (
        <img src={slide.image} alt={slide.title} className="slide-image" />
      )}
      {slide.text && <p className="slide-text">{slide.text}</p>}
      {slide.list && slide.list.map((l,_index)=> <p className="slide-list" key={_index}>→ {l}</p>)}
      {slide.video && (
        <>
          {/* Show poster image while video is loading <img src={slide.poster} alt="Video placeholder" className="slide-video-poster" />*/}
          
         {isActive?
          <video src={slide.video} autoPlay={true} playsInline controls className="slide-video" />
          :''
         }
        </>
      )}
    </div>
  );
};

export default Slide;
