import React, { useState, useEffect } from 'react';
import './payment.css'; // Import your custom CSS file
import { franc_mobile_money, check_franc_transaction_status } from '../flutterwave';
import { GetProfilLocal, UpdateProfilFirestore, UpdateProspectFirestore } from '../Profil';
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('6836749943046775');

function Widthdraw() {
    const [profil, setProfil] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [level, setLevel] = useState(1);
    const [investmentAmount, setInvestmentAmount] = useState(5);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    //GET PROFIL INFOS
    useEffect(() => {
        if (!GetProfilLocal()) {
            window.location.assign('login/')
        }
        setProfil(GetProfilLocal());
        ReactPixel.pageView(); // For tracking page view        
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            default:
                break;
        }
    };
    const handleLevelChange = (_lev) => {
        setLevel(_lev);
        if (_lev === 1 && investmentAmount !== 5)
            setInvestmentAmount(5)
        else if (_lev === 2 && investmentAmount !== 30)
            setInvestmentAmount(30)
        else if (_lev === 3 && investmentAmount !== 60)
            setInvestmentAmount(60)

    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        let sss = () => {
            let _name = level === 1 ? "Kadoma" : (level === 2 ? "Khartoum" : "Kéniéba");
            let number = phoneNumber;
            if (!number.startsWith("+") && !number.startsWith("237") && !number.startsWith("226") && !number.startsWith("225") && !number.startsWith("221")) {
                let indictif = (profil.country == "CM") ? "237" : (profil.country == "CI") ? "225" : (profil.country == "SN") ? "221" : "226";
                number = indictif + number.replace(" ", "");
            }
            number = number.replace('+', '');
            let investSummary = {
                name: _name,
                amount: investmentAmount,
                level: level,
                phoneNumber: number
            }
            franc_mobile_money("SM-" + profil.number + "-" + Date.now(), profil.name, profil.email, number, investmentAmount * 650, profil.country, (data) => {//
                console.log("payment : ", data)
                let interval;
                if (data.status && data.status === "error") {
                    alert('Une érreur est survenue, veillez vérifier le solde et réessayer!')
                    setLoading(false);
                    window.location.reload();
                } else {
                    interval = setInterval(() => {
                        check_franc_transaction_status(data.data.id, (response) => {
                            console.log("checked payment", response)
                            if (response.data.status.toLowerCase().includes("success") || response.data.status.toLowerCase().includes("complet")) {
                                ReactPixel.track("Purchase", { currency: "USD", value: investmentAmount });
                                let _p = { ...profil }
                                _p.investments.push({
                                    ...investSummary,
                                    date_of_investment: Date.now(),
                                    last_payment_date: Date.now()
                                })
                                console.log("profil : ", _p)
                                UpdateProfilFirestore(_p, () => {
                                    clearInterval(interval);
                                    setLoading(false);
                                    setCompleted(true);
                                })
                            } else if (response.data.status.toLowerCase().includes("pending")) { }
                            else if (response.data.status.toLowerCase().includes("cancel") || response.data.status.toLowerCase().includes("fail") || response.data.status.toLowerCase().includes("abandon") || response.data.status.toLowerCase().includes("void") || response.data.status.toLowerCase().includes("error")) {
                                clearInterval(interval);
                                alert('Une érreur est survenue, Vérifiez votre solde et réessayez!');
                                    setLoading(false);
                                    // window.location.reload();
                            }
                        })
                    }, 3000)
                }
            })
            console.log(investSummary);
        }
        let prospect = JSON.parse(localStorage.getItem('prospect'));
        if (prospect){ if (prospect.lvl && prospect.lvl > 4) { sss(); } else {
            UpdateProspectFirestore({
                "name": prospect.name,
                "email": prospect.email,
                "date": prospect.date,
                "lvl": 5
            }, () => {
                sss();
            }, () => {
                sss();
            },)
        }
    }
    else 
    sss();
    
        resetForm(); // Clear form data after submission
    };

    const resetForm = () => {
        setPhoneNumber('');
        setLevel(1);
        setInvestmentAmount(5)
    };

    /* const openlink = () => {
         if (level == 1)
             window.location.assign('https://me.lygosapp.com/9roxoKBu')
         else if (level == 2)
             window.location.assign('https://me.lygosapp.com/3L6PScfM')
         else if (level == 3)
             window.location.assign('https://me.lygosapp.com/hAa3nT8A')
     }*/

    return (
        <div className="form">
            {loading ?
                <div className='loader'>
                    <img src='img/logo.png' className='App-logo' alt='logo' />
                    <div className='info-loader'> Veillez Valider cette transaction sur votre téléphone protable et patienter</div>
                </div>
                :
                ""
            }
            {completed ?
                <div className='loader'>
                    <div className='info-loader'> <h3> Transaction éffectué avec succès!</h3>
                        <center><button type="submit" className='mt' onClick={() => { window.location.assign(window.location.origin + "/dashboard") }}>Continuer</button></center>
                    </div>
                </div>
                :
                ""
            }
            <h2>Choisissez un investissement</h2>

            <div className='optionContainer'>
                <div onClick={() => { handleLevelChange(1) }} className={`option ${level === 1 ? 'optionSelected' : ''}`}>
                    <img src='img/gold.jpg' alt='Hararé' className='optionImg' />
                    <div className='optionLabel'>
                        <h3>Kadoma</h3>
                        <p>Revenus Journalier : <b>50%</b></p>
                        <p>Durée d'investissement : <b>60 jours</b></p>
                    </div>
                </div>
                <div onClick={() => { handleLevelChange(2) }} className={`option ${level === 2 ? 'optionSelected' : ''}`}>
                    <img src='img/gold.jpg' alt='Hararé' className='optionImg' />
                    <div className='optionLabel'>
                        <h3>Khartoum</h3>
                        <p>Revenus Journalier : <b>100%</b></p>
                        <p>Durée d'investissement : <b>45 jours</b></p>
                    </div>
                </div>
                <div onClick={() => { handleLevelChange(3) }} className={`option ${level === 3 ? 'optionSelected' : ''}`}>
                    <img src='img/gold.jpg' alt='Hararé' className='optionImg' />
                    <div className='optionLabel'>
                        <h3>Kéniéba</h3>
                        <p>Revenus Journalier : <b>150%</b></p>
                        <p>Durée d'investissement : <b>30 jours</b></p>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="investment">Plan d'investissement</label><br />
                    <select id='investment' name='investment' onChange={(value) => { setInvestmentAmount(parseInt(value.target.value)) }}>
                        {level < 2 ?
                            <option value={"5"}>Testeur $5</option>
                            :
                            ''}
                        {level === 2 ?
                            <option value={"30"}>Partenire $30</option>
                            :
                            ''}
                        {level === 3 ?
                            <option value={"60"}>investisseur $60</option>
                            :
                            ''}
                        {/*
                            <option value={"60"}>Partenire+ $60</option>
                        <option value={"120"}>investisseur $120</option>
                        {level > 1 ?
                            <option value={"240"}>investisseur+ $240</option>
                            :
                            ''}
                        {level > 2 ?
                            <option value={"480"}>Actionnaire $480</option>
                            :
                            ''}*/
                        }

                    </select>
                </div>
                <div className="form-group">

                    Vous allez recevoir jusqu'à <b className='c-orange'>${parseInt(investmentAmount * ((0.5 * level)))}</b> par jour pendant {75 - (level * 15)} jours <br />
                    <h2 className='mt'>Total reçu: <b className='highlightedText'>${parseInt(investmentAmount * ((0.5 * level))) * (75 - (level * 15))}</b></h2>
                </div>

                <div className='paymentBox'>
                    <center className=''><h1>Paiement</h1></center><br />
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Numéro de paiement mobile:</label><br />
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={handleInputChange}
                            placeholder='+234 ...'
                            required
                        />
                    </div>

                    <center><button type="submit">Investir</button></center>
                </div>

            </form>
        </div>
    );
}

export default Widthdraw;
