import React, { useEffect, useState } from 'react';
import './dashboard.css'; // Import your custom CSS file
import { GetProfilLocal, UpdateProfilFirestore, UpdateProspectFirestore } from '../Profil';

function Dashboard() {
    let dt = new Date()
    const [showAffilite, setShowAffilite] = useState(false);
    const [profil, setProfil] = useState([]);
    let tomorrow = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 1)
    const [timer, setTimer] = useState(
        tomorrow - new Date().getTime()
    );
    const [pay_timer, setPayTimer] = useState("00 : 00 : 00");

    useEffect(() => {
        if (!GetProfilLocal()) {
            window.location.assign('login/')
        } else {
            let _profil = GetProfilLocal();
            let _profilchanged = false;

            let prospect = JSON.parse(localStorage.getItem('prospect'));
            if(prospect)
            if (prospect.lvl && prospect.lvl > 5) { } else {
                UpdateProspectFirestore({
                    "name": prospect.name,
                    "email": prospect.email,
                    "date": prospect.date,
                    "lvl": 6
                }, () => {
                }, () => {
                },)
            }


            if (_profil.investments && _profil.investments.length > 0)
                _profil.investments.forEach((investment, index) => {
                    let lastpaidDate = new Date(investment.last_payment_date)
                    let yesterday = new Date(lastpaidDate.getFullYear(), lastpaidDate.getMonth(), lastpaidDate.getDate())

                    console.log(lastpaidDate, " - ", yesterday, ' -- ', Date.now() - yesterday.valueOf())
                    if (Date.now() - yesterday.valueOf() > 86400000) {
                        _profil.solde += parseInt(investment.amount * ((0.5 * investment.level) - ((dt.getDate() - 1) / 31 * 0.07))) * Math.floor((Date.now() - investment.last_payment_date) / 86400000)
                        _profil.investments[index].last_payment_date = Date.now();
                        _profilchanged = true;
                    }
                });
            if (_profilchanged)
                UpdateProfilFirestore(_profil, () => {
                    setProfil(_profil);
                })
            else
                setProfil(_profil);
        }
    }, [])


    const getReturnValues = (countDown) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(tomorrow - new Date().getTime());
            let arr = getReturnValues(timer);
            let timer_milli = "" + (arr[1]) + " : " + (arr[2]) + " : " + (arr[3]);
            setPayTimer(timer_milli)
        }, 1000);

        return () => clearInterval(interval);
    }, [tomorrow]);


    return (
        <div className='dashboard'>
            {showAffilite ?
                <div className='loader' onClick={() => { setShowAffilite(false) }}>

                    <div className='info-loader w100'> <div className='close-btn' onClick={() => { setShowAffilite(false) }}>✕</div>
                        <h3> <center>Votre lien d'affilié!</center></h3>
                        <input
                            type="text"
                            id="affiliateLink"
                            name="affiliateLink"
                            value={window.location.origin + "/?afl=" + profil.number}
                            className='mt'
                            readOnly
                        />
                        <center><button type="submit" className='mt' onClick={() => { navigator.clipboard.writeText(window.location.origin + "/?afl=" + profil.number); alert("text copié dans la presse papier") }}>Copier 🗒️</button></center>
                    </div>
                </div>
                :
                ""
            }
            <div className="head">
                <div className="logo"><img src="../img/logo.png" alt="Logo" width={25} /></div>
                <div className="title">Shaku Minning</div>
                <div className="tools">
                    <img src={profil.image} alt="profil" width={25} className='profilPicture' />
                    <img src='img/settings.png' alt="settings" className='profilPicture' width={25} />
                </div>
            </div>
            <div className='card'>
                <h3 className='mb'>Solde</h3>
                <h1>$ {profil.solde}</h1>
                <h5>prochaine commission : <b className='c-green'>{pay_timer}</b></h5>
                <div className='buttonContainer'>
                    <button className='button' onClick={() => { window.location.assign(window.location.origin + "/widthdraw") }} disabled={(profil.solde > 0) ? false : true}>
                        Retirer</button>
                </div>
            </div>

            <br />
            <div className='linedBox'>
                <h3 className='c-orange mb _mt'>vos investissements</h3>
                {profil.investments && profil.investments.map((investment, index) => (
                    <div className='card mt' key={index}>
                        <h3 className='mb'>{investment.name}</h3>
                        <div className='sapceTextContainer'>
                            <h5 >Investissement:</h5>
                            <h5 >$ {investment.amount}</h5>
                        </div>
                        <div className='sapceTextContainer'>
                            <h5 >Taux de commision du jour:</h5>
                            <h5 > {parseInt(100 * ((0.5 * investment.level) - ((dt.getDate() - 1) / 31 * 0.07)))}%</h5>
                        </div>
                        <div className='sapceTextContainer'>
                            <h5 >Commision du jour:</h5>
                            <h5 className='c-green'>$ {parseInt(investment.amount * ((0.5 * investment.level) - ((dt.getDate() - 1) / 31 * 0.07)))}</h5>
                        </div>
                    </div>
                ))}

                <center><button className='button' onClick={() => { window.location.assign(window.location.origin + "/payment") }}> + Ajouter un Investissement </button></center>
            </div>


            <br />
            <div className='linedBox'>
                <h3 className='c-orange mb _mt'>vos affiliés</h3>
                <center><button className='button' onClick={() => { setShowAffilite(true) }}> + Ajouter un Affilié </button></center>
            </div>
        </div>
    )
}

export default Dashboard;
