import React, { useState } from 'react';
import './Login.css'; // Import your custom CSS file
import { GetProfilFirestore, SetProfilFirestore, UpdateProspectFirestore } from '../Profil';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();

function Login() {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [pswd, setPswd] = useState('');
    const [image, setImage] = useState(null);
    const [isSignup, setIsSignup] = useState(true); // State for login/signup toggle

    if (localStorage.getItem('profil')) {
        window.location.assign('dashboard')
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'pswd':
                setPswd(value);
                break;
            case 'country':
                setCountry(value);
                break;
            default:
                break;
        }
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let sss = () => {
            if (isSignup) {
                const imageRef = ref(storage, "profilpictures/" + phoneNumber + '-pp.jpg');
                const metadata = {
                    contentType: 'image/jpeg',
                };
                uploadBytes(imageRef, image, metadata).then((snapshot) => {
                    getDownloadURL(imageRef).then((url) => {
                        let number = phoneNumber;
                        if (!number.startsWith("+") && !number.startsWith("237") && !number.startsWith("226") && !number.startsWith("225") && !number.startsWith("221")) {
                            let indictif = (country == "CM") ? "237" : (country == "CI") ? "225" : (country == "SN") ? "221" : "226";
                            number = indictif + number.replace(" ", "");
                        }
                        number = number.replace('+', '');
                        SetProfilFirestore({
                            "name": name,
                            "country": country,
                            "number": number,
                            "email": email,
                            "pswd": pswd,
                            "date": Date.now(),
                            "image": url,
                            "solde": 0,
                            "limit": 3,
                            "investments": [],
                            "affilies": [],
                        }, () => {
                            alert('Félicitations! Vous avez désormais un Compte');
                            console.log('saved !!!')
                            window.location.assign('payment/')
                        }, () => {
                            alert('Vous avez déjà un compte, Connectez-vous!');
                            setIsSignup(false);
                            setLoading(false)
                        },)
                    }).catch((error) => {
                        alert('une erreur s\'est produite')
                        window.location.reload()

                    });
                });
            } else {
                let number = phoneNumber;
                if (!number.startsWith("+") && !number.startsWith("237") && !number.startsWith("226") && !number.startsWith("225") && !number.startsWith("221")) {
                    let indictif = (country == "CM") ? "237" : (country == "CI") ? "225" : (country == "SN") ? "221" : "226";
                    number = indictif + number.replace(" ", "");
                }
                number = number.replace('+', '');
                GetProfilFirestore({ "number": number },
                    (_profil) => {
                        if (_profil.pswd === pswd) {
                            alert('Vous êtes connecté avec succès !')
                            window.location.assign('dashboard/')
                        } else {
                            alert('Votre mot de passe ne correspond pas!')
                            setLoading(false)
                        }
                    },
                    () => {
                        alert('aucun compte ne correspond à ce numéro')
                        setLoading(false)

                    }
                )
            }
        }
        setLoading(true)

        let prospect = JSON.parse(localStorage.getItem('prospect'));
        if (prospect) {
            if (prospect.lvl && prospect.lvl > 3) { sss(); } else {
                UpdateProspectFirestore({
                    "name": prospect.name,
                    "email": prospect.email,
                    "date": prospect.date,
                    "lvl": 4
                }, () => {
                    sss();
                }, () => {
                    sss();
                },)
            }
        }
        else 
        sss();

        //resetForm(); // Clear form data after submission
    };


    return (
        <div className="form">
            {loading ?
                <div className='loader'><img src='img/logo.png' className='App-logo' alt='logo' /></div>
                :
                ""
            }
            <center> <h1>{isSignup ? 'S\'enregistrer' : 'Se Connecter'}</h1></center>

            <form onSubmit={handleSubmit}>
                {isSignup ?
                    <div>
                        <div className="form-group">
                            <label htmlFor="name">Nom:</label><br />
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label><br />
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    :
                    <div></div>
                }
                <div className="form-group">
                    <label htmlFor="phoneNumber">numéro de téléphone:</label><br />
                    <input
                        type="tel" // Use tel for phone number input
                        id="phoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="pswd">Mot de passe:</label><br />
                    <input
                        type="password"
                        id="pswd"
                        name="pswd"
                        value={pswd}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {isSignup ?
                    <div>
                        <div className="form-group">
                            <label for="country">Pays</label>
                            <select id="country" name="country" onChange={handleInputChange}>
                                <option value="ZA">Afrique du sud</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="CM">Cameroon</option>
                                <option value="CI">Cote d'Ivoire</option>
                                <option value="RW">Rwanda</option>
                                <option value="SN">Senegal</option>
                                <option value="ZM">Zimbabwe</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="image">Photo de profil:</label><br />
                            <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                                required />
                        </div>
                    </div>
                    :
                    <div></div>
                }

                <button type="submit">{isSignup ? 'Inscription' : 'Connection'}</button>
                <p>
                    {isSignup ? 'Avez-vous déjà un compte?' : "Vous n'avez pas de compte?"}{' '}
                    <button onClick={() => setIsSignup(!isSignup)}>
                        {isSignup ? 'Connection' : 'Inscription'}
                    </button>
                </p>
            </form>
        </div>
    );
}

export default Login;
