import React, { useEffect, useState } from 'react';
import './dashboard.css'; // Import your custom CSS file
import { GetAllProfilsFirestore, GetAllProspectsFirestore } from '../StatsFirestore';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from "@mui/x-charts";

function Stats() {
    const [profils, setProfils] = useState([]);
    const [update, setUpdates] = useState(false);
    const [prospects, setProspects] = useState([]);
    const [datasetprofil, setDataSetprofil] = useState([]);
    const [datasetprospect, setDataSetprospect] = useState([]);
    const [lastDay, setLastDay] = useState(undefined);
    const [lastDayProspect, setLastDayProspect] = useState(undefined);


    let pp_num = 0;
    let pi_num = 0;
    let pl_num = 0;
    let ppay_num = 0;
    let p_dashboard = 0;
    
    function selection_sort(A) {
        var len = A.length;
        for (var i = 0; i < len - 1; i = i + 1) {
            var j_min = i;
            for (var j = i + 1; j < len; j = j + 1) {
                A[j].date = A[j].date ? A[j].date : 1709305443000;
                A[j_min].date = A[j_min].date ? A[j_min].date : 1709305443000;
                if (parseInt(A[j].date) < parseInt(A[j_min].date)) {
                    j_min = j;
                } else { }
            }
            if (j_min !== i) {
                swap(A, i, j_min);
            } else { }
        }
    }
    function swap(A, x, y) { var temp = A[x]; A[x] = A[y]; A[y] = temp; }

    useEffect(() => {
        const process_dataset_profil = (_profils)=>{
            console.log(_profils)
            // SORT PROFILS
            selection_sort(_profils)
            let start_date = 1709251200000; //86400000
            let graph_dataset = [{
                "matin": 0,
                "av-midi": 0,
                "ap-midi": 0,
                "soir": 0,
                "date": new Date(start_date).getDate() + ' - ' + (parseInt(new Date(start_date).getMonth()) + 1),

            }]
            _profils.forEach((prof) => {
                if (prof.date < start_date + 86400000) {
                    if (prof.date < start_date + 21600000)
                        graph_dataset[graph_dataset.length - 1].matin++;
                    else if (prof.date < start_date + 43200000)
                        graph_dataset[graph_dataset.length - 1]["av-midi"]++;
                    else if (prof.date < start_date + 64800000)
                        graph_dataset[graph_dataset.length - 1]["ap-midi"]++;
                    else
                        graph_dataset[graph_dataset.length - 1]["soir"]++;
                } else {
                    start_date += 86400000;
                    graph_dataset.push({
                        "matin": 0,
                        "av-midi": 0,
                        "ap-midi": 0,
                        "soir": 0,
                        "date": new Date(start_date).getDate() + ' - ' + (parseInt(new Date(start_date).getMonth()) + 1),
                    })
                    if (prof.date < start_date + 21600000)
                        graph_dataset[graph_dataset.length - 1].matin++;
                    else if (prof.date < start_date + 43200000)
                        graph_dataset[graph_dataset.length - 1]["av-midi"]++;
                    else if (prof.date < start_date + 64800000)
                        graph_dataset[graph_dataset.length - 1]["ap-midi"]++;
                    else
                        graph_dataset[graph_dataset.length - 1]["soir"]++;
                }
            })
            setProfils(_profils)
            setLastDay(start_date)
            setDataSetprofil(graph_dataset)
        }

        const process_dataset_prospect = (_profils) => {
            selection_sort(_profils)
            let start_date = 1709251200000; //86400000
            let graph_dataset = [{
                "matin": 0,
                "av-midi": 0,
                "ap-midi": 0,
                "soir": 0,
                "date": new Date(start_date).getDate() + ' - ' + (parseInt(new Date(start_date).getMonth()) + 1),

            }]
            _profils.forEach((prof) => {
                if (prof.date < start_date + 86400000) {
                    if (prof.date < start_date + 21600000)
                        graph_dataset[graph_dataset.length - 1].matin++;
                    else if (prof.date < start_date + 43200000)
                        graph_dataset[graph_dataset.length - 1]["av-midi"]++;
                    else if (prof.date < start_date + 64800000)
                        graph_dataset[graph_dataset.length - 1]["ap-midi"]++;
                    else
                        graph_dataset[graph_dataset.length - 1]["soir"]++;
                } else {
                    start_date += 86400000;
                    graph_dataset.push({
                        "matin": 0,
                        "av-midi": 0,
                        "ap-midi": 0,
                        "soir": 0,
                        "date": new Date(start_date).getDate() + ' - ' + (parseInt(new Date(start_date).getMonth()) + 1),
                    })
                    if (prof.date < start_date + 21600000)
                        graph_dataset[graph_dataset.length - 1].matin++;
                    else if (prof.date < start_date + 43200000)
                        graph_dataset[graph_dataset.length - 1]["av-midi"]++;
                    else if (prof.date < start_date + 64800000)
                        graph_dataset[graph_dataset.length - 1]["ap-midi"]++;
                    else
                        graph_dataset[graph_dataset.length - 1]["soir"]++;
                }
            })
            setLastDayProspect(start_date)
            setDataSetprospect(graph_dataset)
            setProspects(_profils)
            setUpdates(false)
            console.log(_profils)
        }
        
        if (!update) {
            console.log("fetched from local")
            process_dataset_profil(JSON.parse(localStorage.getItem('allProfils')))
            process_dataset_prospect(JSON.parse(localStorage.getItem('allProspects')))
            return;
        }

        GetAllProfilsFirestore(process_dataset_profil)
        GetAllProspectsFirestore(process_dataset_prospect)
    }, [update])

    if (profils && prospects) {
        //if(update)
       prospects.map((pros)=>{
        pp_num += pros.lvl>=1? 1:0;
        pi_num += pros.lvl>=3? 1:0;
        pl_num += pros.lvl>=4? 1:0;
        ppay_num += pros.lvl>=5? 1:0;
       })
    }

    const chartSettingprofil = {
        yAxis: [
            { label: "profils (" + profils.length + ")", },
        ],
        width: 450,
        height: 300,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-5px, 0)",
            },
        },
    };

    const chartSettingprospect = {
        yAxis: [
            { label: "prospect (" + prospects.length + ")", },
        ],
        width: 450,
        height: 300,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-5px, 0)",
            },
        },
    };

    
    return (
        <div className='statsboard'>
        <button className='fix-btn' onClick={()=>{setUpdates(true)}}>Reload</button>
            <div className='display-2'>
                <div className='box'>
                    <center><h2>Profil over time</h2></center>
                    {datasetprofil.length > 0 ?
                        <BarChart
                            dataset={datasetprofil.slice(-3)}
                            xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                            series={[
                                { dataKey: 'matin', label: 'Matin' },
                                { dataKey: 'av-midi', label: 'Avant Midi' },
                                { dataKey: 'ap-midi', label: 'Après Midi' },
                                { dataKey: 'soir', label: 'Soir' },
                            ]}
                            {...chartSettingprofil}
                        />
                        :
                        ''
                    }

                </div>
                <div className='box'>
                    <center><h2>Prospects over time</h2></center>

                    {datasetprospect.length > 0 ?
                        <BarChart
                            dataset={datasetprospect.slice(-3)}
                            xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                            series={[
                                { dataKey: 'matin', label: 'Matin' },
                                { dataKey: 'av-midi', label: 'Avant Midi' },
                                { dataKey: 'ap-midi', label: 'Après Midi' },
                                { dataKey: 'soir', label: 'Soir' },
                            ]}
                            {...chartSettingprospect}
                        />
                        :
                        ''
                    }
                </div>
            </div>

            <div className='display-2'>
                <div className='box'>
                    <center><h2>Stats Personnes</h2></center>
                    {datasetprofil.length > 0 ?
                        <div className='stats-clients'>
                            <div>Prospects : <span>{prospects.length}</span></div>
                            <div>Profils : <span>{profils.length}</span></div>
                            <div>prospect conversion : <span>{parseInt((profils.length / prospects.length) * 100)}%</span></div>

                        </div>
                        :
                        ''
                    }

                </div>
                <div className='box'>
                    <center><h2>Stats de visites des Pages</h2></center>
                    {datasetprospect.length > 0 ?
                        <div className='stats-clients'>
                            <div>Page de prospect : <span>{pp_num}</span></div>
                            <div>Page d'info : <span>{pi_num}</span></div>
                            <div>Page de login : <span>{pl_num}</span></div>
                            <div>Page de paiement : <span>{ppay_num}</span></div>
                            <div>Dashboard page : <span>{p_dashboard}</span></div><br />
                            <div>TDC de prospect par la page d'infos: <span>{parseInt((pi_num / pp_num) * 100)}%</span></div>
                            <div>TDC de prospect par la page de login: <span>{parseInt((pl_num / pp_num) * 100)}%</span></div>
                            <div>TDC de prospect qui tentent un paiement: <span>{parseInt((ppay_num / pp_num) * 100)}%</span></div>
                            <div>TDC de prospect qui ont souscris: <span>{parseInt((p_dashboard / pp_num) * 100)}%</span></div>

                        </div>
                        :
                        ''
                    }
                </div>
            </div>

        </div>
    )
}

export default Stats;
