import './App.css';
import React from 'react';
import Login from './Login/index';
import InfoPage from './InfoPage/index'
import { Routes, Route } from 'react-router-dom';

import Payment from './payment/index';
import Dashboard from './Dashboard/index';
import Widthdraw from './widthdraw/index';
import Stats from './stats/index';
import Prospect from './Prospect/index';
import JoinWhatsapp from './joinwhatsapp/index';
 

function App() {

  return (
       <Routes className="App">
        <Route path="payment/" element={<Payment />} />
        <Route path="login/" element={<Login />} />
        <Route path="/" element={<InfoPage />} />
        <Route path="prospect/" element={<Prospect />} />
        <Route path="dashboard/" element={<Dashboard />} />
        <Route path="widthdraw/" element={<Widthdraw />} />
        <Route path="statsxxdcttfz11/" element={<Stats />} />
        <Route path="join/" element={<JoinWhatsapp />} />
      </Routes>
  );
}

export default App;
