import $ from "jquery";

// INITIATE PAYMENT
const send_inscription_mail = async (toEmail, toName, _callback, fromName = undefined, fromMail = undefined) => {
    let d = {
        key : "1183f28f310befe0fd3d671fbc5b9842",
        sec_key: "910db14fcf3ed3eea546a346e0af1e1a",
        messages: [
            {
                From: {
                    Email: fromMail ? fromMail : "ibrahim.shaku.ashar@gmail.com",
                    Name: fromName ? fromName : "[Important] Shaku Mining"
                },
                To: [
                    {
                        Email: toEmail,
                        Name: toName
                    }
                ],
                Subject: toName + ", Bienvenue à Shaku Mining Investment!",
                TextPart: "Il est temps de découvrir la liberté financière!",
                HTMLPart: '<center style="padding: 15px 50px;display: grid;"> <h1>Bienvenue à Shaku Mining</h1> <h2>Vous êtes désormais membre de Shaku investment</h2> <a href="https://investment.shaku-mining.org/payment" class="btn-bl" style=" padding: 40px 40px; border-radius: 20px; font-size: 40px; background-color: teal; text-decoration: none; color: white; margin: 10px; "> Investir </a> <br> <p style="margin-bottom: 20px;">Nous prenons très à coeur votre satisfaction et c\'est pour cela que nous mettons à votre disposition notre groupe whatsapp de soutien et de questions </p> <a href="https://chat.whatsapp.com/GjkVzkDgfK27Y9YdvpjyRT" class="btn" style=" padding: 40px 40px; border-radius: 20px; font-size: 1rem; background-color: #4caf50; text-decoration: none; color: white; margin: 10px; "> Rejoignez le groupe whatsapp </a> <a href="https://wa.me/19144606251" class="btn" style=" padding: 40px 40px; border-radius: 20px; font-size: 1rem; background-color: #4caf50; text-decoration: none; color: white; margin: 10px; "> Contacter notre service client </a> </center>'
            }
        ]
    }
    try {
        $.ajax({
            type: "GET",
            url: "https://flutterwave-nodejs.onrender.com/api/mail/send",
            data: d,
            success(data) {
                _callback(data); // The id sent in the function check_franc_transaction_status is data.data.id
            },
        });
    } catch (error) {
        console.log(error)
    }
}

export { send_inscription_mail }