import React, { useState } from 'react';
import { SetProspectFirestore } from '../Profil';
import { send_inscription_mail } from '../mail_sms';
import './prospect.css'; // Import your custom CSS file


const Prospect = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        SetProspectFirestore({
            "name": name,
            "email": email,
            "date": Date.now(),
            "lvl": 1
        }, () => {
            send_inscription_mail(email,name,(data)=>{
                console.log(data)
                 window.location.assign('/')
            })            
        }, () => {
            send_inscription_mail(email,name,(data)=>{
                console.log(data)
                window.location.assign('/')
            })
        },)
        //resetForm(); // Clear form data after submission
    };


    return (
        <div className="prospect-page">
            <center className='heading'><img src='img/logo.png' alt='logo' width={50}/><h2 className="page-title" id='title'>Shaku Minning Investissement</h2></center>

            <p>Pour découvrir cette opportunité et enfin atteindre votre indépendance financière, veillez entrer ces informations et continuer</p>
            <div>
                <div className="form-group">
                    <label htmlFor="name">Nom:</label><br />
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label><br />
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
            <center>
                <button className={`swiper-button-next`} onClick={ handleSubmit} >
                    Suivant
                </button>
            </center>
            <br />
            <br />
            <center><h1>Témoignages</h1>
                <img src='img/om.jpg' alt='orange money' width={350} />
                <img src='img/testimony-1.jpg' alt='orange money' width={350} />
                <img src='img/testimony-2.jpg' alt='orange money' width={350} />
            </center>

            <center>
                <a className={`swiper-button-next`} href='prospect/#title'>
                    Remplir le formulaire
                </a>
            </center>
        </div>
    );
};

export default Prospect;
