// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDpby-ri7-eW-qncOrsWrppnoquSoGm6Ic",
    authDomain: "eboss-af626.firebaseapp.com",
    projectId: "eboss-af626",
    storageBucket: "eboss-af626.appspot.com",
    messagingSenderId: "49027432020",
    appId: "1:49027432020:web:da98f0aa5305341493b7c1",
    measurementId: "G-7V0FHL40BY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function GetProfilFirestore(_profil, _callback, _notexistCallback) {
    let profil;
    const docRef = doc(db, "CE_Profils", _profil.number);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        profil = docSnap.data();
        localStorage.setItem('profil', JSON.stringify(profil));
        _callback(profil)
    } else {
        _notexistCallback()
    }
    return profil;
}

const GetProfilLocal = () => {
    let profil = JSON.parse(localStorage.getItem('profil'));
    if (localStorage.getItem('profil'))
        return profil;
    else
        return null;
}

const SetProfilLocal = (profil) => {
    localStorage.setItem('profil', JSON.stringify(profil));
}


const SetProfilFirestore = async (profil, _callback, _existCallback) => {
    let result = false;

    const docRef = doc(db, "CE_Profils", profil.number);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        _existCallback()
        return false;
    }

    try {
        console.log('start saving')
        await setDoc(doc(db, "CE_Profils", profil.number), profil);
        localStorage.setItem('profil', JSON.stringify(profil));
        console.log('end saving')
        _callback();
        result = true;
    } catch (e) {
        console.error(e)

    }
    return result;

}

const SetProspectFirestore = async (profil, _callback, _existCallback) => {
    let result = false;
    console.log(profil)

    const docRef = doc(db, "CE_Prospects", profil.email.split('@')[0]);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        _existCallback()
        return false;
    }

    try {
        console.log('start saving')
        await setDoc(doc(db, "CE_Prospects", profil.email.split('@')[0]), profil);
        localStorage.setItem('prospect', JSON.stringify(profil));
        console.log('end saving')
        _callback();
        result = true;
    } catch (e) {
        console.error(e)

    }
    return result;

}

const UpdateProspectFirestore = async (profil, _callback) => {
    let result = false;
    try {
        console.log('start updating')
        await setDoc(doc(db, "CE_Prospects", profil.email.split('@')[0]), profil);
        localStorage.setItem('prospect', JSON.stringify(profil));
        console.log('end updating')
        _callback();
        result = true;
    } catch (e) {
        console.error(e)
    }
    return result;

}


const UpdateProfilFirestore = async (profil, _callback) => {
    let result = false;
    try {
        console.log('start updating')
        await setDoc(doc(db, "CE_Profils", profil.number), profil);
        localStorage.setItem('profil', JSON.stringify(profil));
        console.log('end updating')
        _callback();
        result = true;
    } catch (e) {
        console.error(e)
    }
    return result;

}

export { GetProfilFirestore, SetProfilFirestore, GetProfilLocal, SetProfilLocal, UpdateProfilFirestore, SetProspectFirestore, UpdateProspectFirestore }

