import { initializeApp } from "firebase/app";
import { doc, getDoc, collection, getDocs, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDpby-ri7-eW-qncOrsWrppnoquSoGm6Ic",
    authDomain: "eboss-af626.firebaseapp.com",
    projectId: "eboss-af626",
    storageBucket: "eboss-af626.appspot.com",
    messagingSenderId: "49027432020",
    appId: "1:49027432020:web:da98f0aa5305341493b7c1",
    measurementId: "G-7V0FHL40BY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


async function GetAllProfilsFirestore(_callback) {
    let profils = [];
    const querySnapshot = await getDocs(collection(db, "CE_Profils"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let docdata = doc.data();
        docdata.id = doc.id;
        profils.push(docdata);
    });
    localStorage.setItem('allProfils', JSON.stringify(profils));
    _callback(profils)

    return profils;
}
async function GetAllProspectsFirestore(_callback) {
    let profils = [];
    const querySnapshot = await getDocs(collection(db, "CE_Prospects"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let docdata = doc.data();
        docdata.id = doc.id;
        profils.push(docdata);
    });

    localStorage.setItem('allProspects', JSON.stringify(profils));
    _callback(profils)

    return profils;
}

export { GetAllProfilsFirestore, GetAllProspectsFirestore }

