import React, { useState, useEffect } from 'react';
import './widthdraw.css'; // Import your custom CSS file
import { GetProfilLocal } from '../Profil';

function Payment() {
    const [showAffilite, setShowAffilite] = useState(false);
    const [profil, setProfil] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [retrievalAmount, setRetrievalAmount] = useState(0);
    const [loading, setLoading] = useState(false);

    //GET PROFIL INFOS
    useEffect(() => {
        let p = GetProfilLocal();
        if(!p.limit){
            p.limit = 0;
        }
        setProfil(p);
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(value.split('$')[1])
        switch (name) {
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'retrievalAmount':
                setRetrievalAmount(value.split('$')[1]);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        resetForm(); // Clear form data after submission
    };

    const resetForm = () => {
        setPhoneNumber('');
        setRetrievalAmount(0)
    };

    /* const openlink = () => {
         if (level == 1)
             window.location.assign('https://me.lygosapp.com/9roxoKBu')
         else if (level == 2)
             window.location.assign('https://me.lygosapp.com/3L6PScfM')
         else if (level == 3)
             window.location.assign('https://me.lygosapp.com/hAa3nT8A')
     }*/

    return (
        <div className="form">
            {loading ?
                <div className='loader'>
                    <img src='img/logo.png' className='App-logo' alt='logo' />
                    <div className='info-loader'> Veillez Valider cette transaction sur votre téléphone protable et patienter</div>
                </div>
                :
                ""
            }
            {showAffilite ?
                <div className='loader' onClick={() => { setShowAffilite(false) }}>

                    <div className='info-loader w100'> <div className='close-btn' onClick={() => { setShowAffilite(false) }}>✕</div>
                        <h3> <center>Votre lien d'affilié!</center></h3>
                        <input
                            type="text"
                            id="affiliateLink"
                            name="affiliateLink"
                            value={window.location.origin + "/?afl=" + profil.number}
                            className='mt'
                            readOnly
                        />
                        <center><button type="submit" className='mt' onClick={() => { navigator.clipboard.writeText(window.location.origin + "/?afl=" + profil.number); alert("text copié dans la presse papier") }}>Copier 🗒️</button></center>
                    </div>
                </div>
                :
                ""
            }
            <center><h2>Choisissez un investissement</h2></center>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                </div>
                <div className="form-group">
                    Pour augmenter votre Limit de retrait, invitez des investisseur via votre lien
                    <center><button className='button' onClick={() => { setShowAffilite(true) }}> + inviter un investisseur </button></center>
                    <center>
                        <h2 className='mt'>Limit de retrait: <b className='highlightedText'>${profil.limit ? profil.limit : 0}</b></h2>
                    </center>
                </div>

                <div className='paymentBox'>
                    <center className=''><h1>Paiement</h1></center><br />
                    <div className="form-group">
                        <label htmlFor="retrievalAmount">Montant à retirer en dollar (nous convertirons en votre monnaie Local)</label><br />
                        <input
                            type="text"
                            id="retrievalAmount"
                            name="retrievalAmount"
                            value={"$" + retrievalAmount}
                            onChange={handleInputChange}
                            placeholder='Ex: $100'
                            className={retrievalAmount > profil.limit? 'input-alert': ''}
                            required
                        />
                       {retrievalAmount > profil.limit? 
                        <p className='c-orange'>Vous ne pouvez pas retirer plus que votre limit de retrait</p>
                       :
                       ''}
                    </div>
                    <div className="form-group">
                            <label htmlFor="country">Pays</label>
                            <select id="country" name="country" onChange={handleInputChange}>
                                <option value="ZA">Afrique du sud</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="CM">Cameroon</option>
                                <option value="CI">Cote d'Ivoire</option>
                                <option value="RW">Rwanda</option>
                                <option value="SN">Senegal</option>
                                <option value="ZM">Zimbabwe</option>
                            </select>
                        </div>
                    <div className="form-group">
                        <label htmlFor="operator">Operateur</label>
                        <select id="operator" name="operator" onChange={handleInputChange}>
                            <option value="MTN">MTN Money</option>
                            <option value="OM">Orange Money</option>
                            <option value="WV">Wave</option>
                            <option value="FR">Free</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Numéro de paiement mobile:</label><br />
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={handleInputChange}
                            placeholder='+234 ...'
                            required
                        />
                    </div>

                    <center><button type="submit" disabled= {(retrievalAmount > profil.limit || retrievalAmount === 0)? true: false}>Retirer</button></center>
                </div>

            </form>
        </div>
    );
}

export default Payment;
