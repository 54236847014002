import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateProspectFirestore } from '../Profil';
import './InfoPage.css'; // Import your custom CSS file

import Slide from './Slide';

const InfoPage = () => {
    const [currentSlide, setCurrentSlide] = useState(0); // Track current slide
    const [videoTimer, setVideoTimer] = useState(100); // Track current slide
    const [displayTimer, setDisplayTimer] = useState(false); // Track current slide
    const navigate = useNavigate();
    let videoInterval;
    const videoLength = 100;

    const slides = [
        {
            title: "Qu'est-ce que Shaku Minning?",
            text: "Shaku Mining est une entreprise Zimbabweénne d'exploitation minière. Nous exploitons les mines d'or d'Afrique du sud, du Sudan et du Mali",
            image: 'img/slide-1.jpg',
            video: '', // Replace with your video file path (optional)
        },
        // Add more slides using the same format
        {
            title: 'Pourquoi cherchons nous des investisseur?',
            text: "L'exploitation minière étant sous la supervision des banques centrales des pays concernés, nous ne recevons qu'on pourcentage mensuel de nos cargaisons d'or chaque mois afin d'assurer la stabilité économique de ces pays. Mais n'étant pas suffisant pour financer les activités minières, nous faisons appel à des investisseurs pour accroître notre capital et ainsi maintenir une exploitation continue de nos mines",
            image: 'img/slide-2.jpg',
            video: '', // Replace with your video file path (optional)
        },
        // ...

        {
            title: "Qu'est-ce que j'y gagne?",
            text: 'En investissant dans Shaku minning, vous gagnez ceci :',
            list: [
                "jusqu'à 20% de retour sur investissement par jour pendant 60 jours",
                "Passer de 20% à jusqu'à 50% en invitant des personnes à investir",
                "Retirer vos gains via MTN, Orange Money, Wave, m-pesa et bien d'autres",
            ],
            image: 'img/slide-3.jpg',
            video: '', // Replace with your video file path (optional)
        },
        {
            title: 'Regardez cette vidéo explicatif',
            video: 'https://firebasestorage.googleapis.com/v0/b/eboss-af626.appspot.com/o/Shaku%20mining%20-%20SD.mp4?alt=media&token=932d853f-604a-4726-9ee9-56ddb0b22a11',
            //poster: '',  Replace with your poster image path
        },
    ];

    const handleNext = () => {
        setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, slides.length - 1));
        console.log(currentSlide + 1, slides.length - 1)
        if (currentSlide + 1 === slides.length - 1) {
            setDisplayTimer(true)
            let endDate = Date.now() + videoLength * 1000;
            videoInterval = setInterval(() => {
                setVideoTimer(parseInt((endDate - Date.now()) / 1000))
                if (parseInt((endDate - Date.now()) / 1000) < 1) {
                    setVideoTimer(0);
                    setDisplayTimer(false);
                    clearInterval(videoInterval);
                }
            }, 1000)
        } else if (currentSlide + 1 > slides.length - 1) {

        }
    };

    const handlePrevious = () => {
        setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0));
        setVideoTimer(0);
        setDisplayTimer(false);
        clearInterval(videoInterval);
    };


    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
            handlePrevious();

        } else if (event.key === 'ArrowRight') {
            handleNext();
        }
    };


    return (
        <div className="info-page" onKeyDown={handleKeyDown}>
            <center><h2 className="page-title">Shaku Minning!</h2></center>

            <div className="slide-container">
                {slides.map((slide, index) => (
                    <Slide key={index} slide={slide} slideIndex={index} currentIndex={currentSlide} />
                ))}
            </div>

            <div className="navigation-buttons">
                {currentSlide !== 0 ?
                    <button className={`swiper-button-prev ${displayTimer ? 'inactive' : ''}`} onClick={handlePrevious} disabled={displayTimer ? true : false}>
                        Précédent {displayTimer ? videoTimer : ''}
                    </button>
                    :
                    <div></div>
                }

                <button className={`swiper-button-next ${displayTimer ? 'inactive' : ''}`} onClick={() => {
                    if (currentSlide === slides.length - 1) {
                        let prospect = JSON.parse(localStorage.getItem('prospect'));
                        if (prospect){ if (prospect.lvl && prospect.lvl > 2) { navigate('/login', { replace: true }); } else {
                            UpdateProspectFirestore({
                                "name": prospect.name,
                                "email": prospect.email,
                                "date": prospect.date,
                                "lvl": 3
                            }, () => {
                                navigate('/join', { replace: true });
                            }, () => {
                                navigate('/join', { replace: true });
                            },)
                        } }
                        else 
                        navigate('/join', { replace: true });
                    } else { handleNext() }
                }} disabled={displayTimer ? true : false}>
                    Suivant {displayTimer ? videoTimer : ''}
                </button>
            </div>
        </div>
    );
};

export default InfoPage;
