import React from 'react';
import './prospect.css'; // Import your custom CSS file


const JoinWhatsapp = () => {
    

    return (
        <div className="prospect-page">
            <center className='heading'><img src='img/logo.png' alt='logo' width={50}/><h2 className="page-title" id='title'>Shaku Minning Investissement</h2></center>
            <br/>
            <center><p>Pour découvrir cette opportunité et enfin atteindre votre indépendance financière, Vous avez 2 choix</p></center>

            <div className='buttonContainer-2 mt-2'>
                <a className='button m-0' onClick={()=>{window.location.assign('payment/')}}>Investir Maintenant !</a>
                <a className='button btn-green m-0' onClick={()=>{window.location.assign('https://chat.whatsapp.com/GjkVzkDgfK27Y9YdvpjyRT')}}>
                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/640px-WhatsApp_icon.png' alt='-' width='25px' className='img'/> <div> Nous contacter!</div>  </a>
            </div>
            
        </div>
    );
};

export default JoinWhatsapp;
