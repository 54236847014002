import $ from "jquery";
const FLW_PUBLIC_KEY = "FLWPUBK-d4c9c3ef45d2fe197dc6b4afe9c5cea9-X";
const FLW_SECRET_KEY = "FLWSECK-b9bdfa67d1598b3ba531255485048846-18e1883944bvt-X";

// INITIATE PAYMENT
const franc_mobile_money = async (ref, name, email, number, amount, country, _callback) => {
    let currency = "XOF";
    if (country === "CM") {
        currency = "XAF"
    }
    if (!number.startsWith("+") && !number.startsWith("237") && !number.startsWith("226") && !number.startsWith("225") && !number.startsWith("221")) {
        let indictif = (country == "CM") ? "237" : (country == "CI") ? "225" : (country == "SN") ? "221" : "226";
        number = indictif + number.replace(" ", "");
    }
    number = number.replace('+','');
    let d = {
        "pub_key": FLW_PUBLIC_KEY,
        "sec_key": FLW_SECRET_KEY,
        "fullname": name,
        "email": email,
        "number": number,
        "tx_ref": ref,
        "country": country,
        "currency": currency,
        "amount": amount,
    }
    try {
        $.ajax({
            type: "GET",
            url: "https://flutterwave-nodejs.onrender.com/api/collect",
            data: d,
            success(data) {
                _callback(data); // The id sent in the function check_franc_transaction_status is data.data.id
            },
        });
    } catch (error) {
        console.log(error)
    }
}

//SEND MONEY TO A NUMBER FROM FW PAYOUT BALANCE #NOT_YET_TESTED_THIS_FUNCTION
const send_mobile_money = async (ref, name, network, number, amount, country, _callback) => {
    let currency = "XOF";
    if (country === "CM") {
        currency = "XAF"
    }
    if (!number.startsWith("+") && !number.startsWith("237") && !number.startsWith("226") && !number.startsWith("225") && !number.startsWith("221")) {
        let indictif = (country == "CM") ? "237" : (country == "CI") ? "225" : (country == "SN") ? "221" : "226";
        number = indictif + number.replace(" ", "");
    }
    let d = {
        "pub_key": FLW_PUBLIC_KEY,
        "sec_key": FLW_SECRET_KEY,
        "fullname": name,
        "network": network,
        "number": number,
        "tx_ref": ref,
        "country": country,
        "currency": currency,
        "amount": amount,
    }
    try {
        $.ajax({
            type: "GET",
            url: "https://flutterwave-nodejs.onrender.com/api/collect",
            data: d,
            success(data) {
                _callback(data);
            },
        });
    } catch (error) {
        console.log(error)
    }
}

// CHECK THE INITIATED TRANSACTION STATUS BY SENDING THE ID FROM data.data.id
const check_franc_transaction_status = async (ref, _callback) => {
    try {
        $.ajax({
            type: "GET",
            url: "https://flutterwave-nodejs.onrender.com/api/check",
            data: {
                "pub_key": FLW_PUBLIC_KEY,
                "sec_key": FLW_SECRET_KEY,
                "tx_ref": ref
            },
            success(data) {
                _callback(data);
            },
        });
    } catch (error) {
        console.log(error)
    }
}
export { franc_mobile_money, check_franc_transaction_status, send_mobile_money }